<template>
  <PrintList />
</template>

<script>
  export default {
    components: {
      PrintList: () => import('@/components/stock/print/PrintList')
    }
  }
</script>